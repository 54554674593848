<template>
  <div>
    <ProfileForm v-if="activeProfileForm==true" v-model="form" @updateProfile="updateProfile" :queue="queue" @queueChange="queueChange" @EMIT_activeProfileForm="(given)=>this.activeProfileForm = given"/>
    <v-snackbar v-model="ui.snackbar.display">
        <str v-if="ui.snackbar.message" :index="ui.snackbar.message"/>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="ui.snackbar.display = false"
        >
          <str index="buttons.close"/>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import ProfileForm from '@/components/Authorizer/ProfileForm.vue'
export default {
    mounted: function(){
        let attributes = this.$store.getters.user.attributes
        let use = this.fieldMap
        for(let i=0; i<use.length; i++){
            let field = use[i]
            this.form[field.target] = attributes[field.key]
        }
    },
    components: {
        ProfileForm
    },
    data: function(){
        return {
            activeProfileForm: true,
            queue: 0,
            ui: {
                snackbar: {
                    display: false,
                    message: null
                }
            },
            fieldMap: [
                {target:'given_name',key:'given_name'},
                {target:'family_name',key:'family_name'},
                {target:'affiliation',key:'custom:affiliation'},
                {target:'hcp_job_profession',key:'custom:hcp_job_profession'},
                {target:'hcp_specialty',key:'custom:hcp_specialty'},
                {target:'hubspot_id',key:'custom:hubspot_id'},
                {target:'country',key:'custom:country'},
                {target:'prov_state',key:'custom:prov_state'},
                {target:'timezone',key:'custom:timezone'},
                {target:'hcp',key:'custom:hcp'},
                {target:'is_accurate',key:'custom:is_accurate'},
                {target:'language',key:'custom:language'}
            ],
            form: {
                given_name: null,
                family_name: null,
                affiliation: null,
                country: null,
                prov_state: null,
                hcp_job_profession: null,
                hcp_specialty: null,
                hubspot_id: null,
                timezone: 'Eastern',
                hcp: 'N',
                is_accurate: 'N',
                language: null
            }
        }
    },
    methods: {
        updateProfile: async function(data){
            this.queue++
            let form = this.form
            let fieldMap = this.fieldMap
            let customData = {}
            for(let i=0; i<fieldMap.length; i++){
                let field = fieldMap[i]
                customData[field.key] = form[field.target]
            }
            let response = await this.$store.getters.Authorizer.updateAttribute(customData)
            await this.sendRequest('patch','/api/profile',customData)
            if(response=='SUCCESS'){
                this.ui.snackbar.message = 'messages.success.saved'
            }else{
                this.ui.snackbar.message = 'messages.errors.not_saved'
            }
            this.ui.snackbar.display = true
            this.$emit('updateProfile',response)
            this.queue--
            if (this.$store.getters.profileFormFistLoginUpdateState==='in-process'){
                this.$store.dispatch('profileFormFistLoginUpdateState','finished')
                this.$store.dispatch('profileFormState','closed')
            }
        },
        queueChange: function(ask){
            if(ask=='++'){this.queue++}
            if(ask=='--'){this.queue--}
        },
        busy: function(){
            return this.queue>0
        }
    },
    watch: {
        queue: function(){
            if(this.queue<0){
                this.queue=0
            }
        },
        activeProfileForm: function(){
            console.log('changed activeProfileForm in Profile');
            // this.$emit('EMIT_activeProfileForm', false);
            this.$emit('EMIT_activeProfileForm', false);
        }
    }
}
</script>

<style>

</style>
