<template>
    <div id="Banner" :style="{'background-image':`URL(${images.Background})`}">
        <template v-if="routeName=='Home'">
            <div class='logo'>
                <v-img :src="images.Logo"></v-img>
            </div>
            <div class='bar'/>
            <div class='text'>
                <str index="banner.home.text"/>
            </div>
        </template>

        <template v-if="routeName=='Resources'">
            <div style="text-align: center; align-self: center; color: white;">
                <h1 style='margin-bottom:16px;'>
                    <str index="banner.resources.title"/>
                </h1>
                <str index="banner.resources.text"/>
            </div>
        </template>
    </div>
</template>

<script>
import Resources from './resources-background.png'
import Logo from '@/assets/logo.png'
export default {
    computed: {
        routeName: function(){
            return this.$route.name
        },
        images: function(){
            let routeName = this.routeName
            return {
                Background: routeName=='Resources' ? Resources : '',
                Logo
            }
        }
    }
}
</script>

<style scoped lang="scss">

    #Banner {
        display: flex;
        justify-content: center;
        padding: 48px 8%;
        background-size: cover;

        @media(max-width: 1024px){
            flex-flow: column;
        }

        .logo,
        .text {
            width: 40%;
            align-self: center;

            @media(max-width: 1024px){ // lg <= 1200
                width: 56%;
            }
            @media(max-width: 991px){ // md <= 992
                width: 64%;
            }
            @media(max-width: 767px){ // sm <= 768
                width: 72%;
            }
            @media(max-width: 575px){ // xs <= 576
                width: 80%;
            }
        }

        .text {
            text-align: left;
        }

        .bar {
            align-self: center;
            width: 2px;
            height: 120px;
            background-color: $orange;
            margin: 0px 80px 0px 80px;

            @media(max-width: 1024px){
                width: 120px;
                height: 2px;
                margin: 24px 0;
            }
        }
    }
</style>
