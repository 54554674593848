<template>
<div id="ProfileForm">
    <div class='profileFormCard' :style="windowHeight<=745 ? 'overflow-y:auto' :'hidden'">
        <v-form ref="moreInfo" v-model="validForms.moreInfo">

            <div class='fields'>

                <div class='description'>
                    <p>Update</p>
                    <p>your profile</p>
                </div>

                <!-- Account Info -->
                <div class='accountInfo'>
                    <div class="header">
                        <v-icon color="#c45d4a" style='margin-right:8px;' large>mdi-account-cog</v-icon>
                        <str index="authenticator.edit_profile.info_account"/>
                    </div>
                    <v-text-field v-model="model.given_name" :rules="rules.required" outlined rounded dense required background-color="white">
                        <template v-slot:label>
                            <str index="forms.given_name"/>
                        </template>
                    </v-text-field>
                    <v-text-field v-model="model.family_name" :rules="rules.required" outlined rounded dense required background-color="white">
                        <template v-slot:label>
                            <str index="forms.family_name"/>
                        </template>
                    </v-text-field>

                    <div v-show="false" style="text-align: left;">
                        <v-select @change="$store.commit('language',temp.language)" v-model="temp.language" :items="[{text: 'English', value: 'en-ca'}, {text: 'Français', value: 'fr-ca'}]" outlined rounded dense>
                            <template v-slot:label>
                                <str index="authenticator.edit_profile.preferred_language"/>
                            </template>
                        </v-select>
                    </div>

                    <v-text-field v-model="email.new" :rules="rules.validEmail" outlined rounded dense required :readonly="email_readonly" :disabled="email_readonly" background-color="white">
                        <template v-slot:label>
                            <str index="forms.email"/>
                        </template>
                        <!-- Change Email button -->
                        <template v-slot:append-outer v-if="!email_readonly">
                            <v-btn style='text-transform:initial; margin-top:-6px;' rounded :disabled="email.new==$store.getters.user.attributes.email || busy" text @click="requestEmailChange">
                                <str index="authenticator.buttons.change_email"/>
                            </v-btn>
                        </template>
                    </v-text-field>
                    <!-- Change Password button -->
                    <v-btn style='width:100%; text-transform:initial; background-color:rgb(232,232,232); border:1px solid lightgrey;' class="themed" @click="ui.changePassword = true"  outlined rounded>
                        <str index="authenticator.buttons.change_password"/>
                    </v-btn>
                </div>

                <!-- Additional Info -->
                <div class='additionalInfo'>
                    <div class="header">
                        <v-icon large color="#c45d4a" style='margin-right:8px;' >mdi-account-circle-outline</v-icon>
                        <str index="authenticator.edit_profile.info_additional"/>
                    </div>
                    <template v-for="(field, field_index) in fields">

                        <v-text-field v-if="field.type=='number'" :key="`field_${field_index}`" v-model="model[field.target]" :onkeydown="'javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))'"
                            type="number"
                            :required="field.required"
                            :rules="field.rules"
                            outlined rounded dense
                        >
                            <template v-slot:label>
                                <str :index="field.label"/>
                            </template>
                        </v-text-field>

                        <v-text-field v-if="field.type=='textfield'" :key="`field_${field_index}`" v-model="model[field.target]"
                            :required="field.required"
                            :rules="field.rules"
                            outlined rounded dense
                            background-color="white"
                        >
                            <template v-slot:label>
                                <str :index="field.label"/>
                            </template>
                        </v-text-field>

                        <v-autocomplete v-if="field.type=='autocomplete'" :key="`field_${field_index}`" v-model="model[field.target]"
                            :items="field.items"
                            :required="field.required"
                            :rules="field.rules"
                            background-color="white"
                            autocomplete="disable_autocomplete"
                            outlined rounded dense
                        >
                            <template v-slot:label>
                                <str :index="field.label" />
                            </template>
                        </v-autocomplete>

                        <v-select v-if="field.type=='select'" :key="`field_${field_index}`" v-model="model[field.target]"
                            :items="field.items"
                            :required="field.required"
                            :rules="field.rules"
                            background-color="white"
                            outlined rounded dense
                        >
                            <template v-slot:label>
                                <str :index="field.label" />
                            </template>
                        </v-select>

                    </template>
                </div>
                <!-- END additionalInfo -->
            </div>
            <!-- END fields -->

            <div class='buttonsAndConfirmBox'>
                <div class="attest">
                    <template v-for="(field, field_index) in fields">
                        <v-checkbox v-if="field.type=='attest'" true-value="Y" :false-value="null" :key="`field_${field_index}`" v-model="model[field.target]"
                            :items="field.items"
                            :required="field.required"
                            :rules="field.rules"
                            outlined rounded dense
                        >
                            <template v-slot:label>
                                <str :index="field.label"/>
                            </template>
                        </v-checkbox>
                    </template>
                </div>
                <v-btn v-if="validForms.moreInfo && !queue>0 && $store.getters.profileFormFistLoginUpdateState!=='in-process'" class='closeButton' @click="$store.dispatch('profileFormState', 'closed');$emit('EMIT_activeProfileForm',false)" color="rgba(0,0,0,0)" elevation='0' rounded>
                    <v-icon>mdi-close-circle</v-icon>
                </v-btn>
                <v-btn class='saveButton button_profileForm' style='text-transform:initial;' :disabled="!validForms.moreInfo || queue>0" color="" :rounded="true" @click="$emit('updateProfile',model)">
                    <v-progress-circular v-if="queue>0" indeterminate color="primary"/>
                    <str index="buttons.save"/>
                </v-btn>
            </div>

        </v-form>
    </div>
    <!-- END profileFormCard -->


    <!-- Change Email dialog -->
    <v-dialog v-model="ui.changeEmail" persistent width="400" :hide-overlay="true" :retain-focus="true">
        <v-card v-if="ui.changeEmail" style='position:relative;'>
            <!-- Underlayed image -->
            <div style="
                background-image:url('../img/CanClearLogo_leaf.b16f0d65.png');
                background-size: contain;
                width: 100%;
                height: 100%;
                position: absolute;
                bottom: -40px;
                left: -80px;
                opacity: 0.1;">
            </div>

            <v-card-title style='margin-bottom:16px;'>
                <str index="authenticator.edit_profile.change_email.title"/>
            </v-card-title>

            <v-card-text>
                <str index="authenticator.edit_profile.change_email.text"/>: {{email.new}}
                <v-otp-input
                    v-model="email.pin"
                    type="number"
                    :length="config.pinLength"
                />
            </v-card-text>

            <v-card-actions>
                <v-btn class="themed" style='text-transform:initial; width:100%; margin-top:16px; background-color:#c45d4a; color:white;' rounded color="primary" :disabled="email.pin==null || email.pin.length<config.pinLength" @click="verifyEmailChange">
                    <str index="authenticator.buttons.verify"/>
                </v-btn>
            </v-card-actions>

            <v-card-actions>
                <v-btn @click="ui.changeEmail=false" style='position:absolute; top:8px; right:8px;' color="rgba(0,0,0,0)" elevation='0' rounded small>
                    <v-icon left>mdi-close-circle</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- Change Password dialog -->
    <v-dialog id='hello' v-model="ui.changePassword"  width="400" :hide-overlay="true" :retain-focus="true" persistent style='position:relative;  border-radius: 16px !important; box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%) !important;'>
        <v-card class='changePasswordDialog'>
            <!-- Underlayed image -->
            <div style="
                background-image:url('../img/CanClearLogo_leaf.b16f0d65.png');
                background-size: contain;
                width: 100%;
                height: 100%;
                position: absolute;
                bottom: -120px;
                left: -120px;
                opacity: 0.1;">
            </div>

            <v-card-title style='margin-bottom:16px;'>
                <str index="authenticator.edit_profile.change_password.title"/>
            </v-card-title>

            <v-card-text style="text-align: center;">
                <v-form ref="changePassword" v-model="validForms.changePassword">
                    <v-text-field type="password" v-model="password.new" :label="labels.forms.password._text[language]" :rules="rules.validPassword" outlined rounded dense required background-color="white">
                        <template v-slot:message="{message, key}">
                            <span v-html="message"/>
                        </template>
                    </v-text-field>
                    <v-text-field type="password" v-model="password.confirmation" @keyup="$refs.changePassword.validate()" :rules="rules.verifyPasswords" outlined rounded dense required background-color="white">
                        <template v-slot:label>
                            <str index="forms.confirm_password"/>
                        </template>
                    </v-text-field>
                </v-form>

                <str index="authenticator.edit_profile.change_password.text"/>: {{$store.getters.user.attributes.email}}
                <br>
                <!-- Send button -->
                <v-btn style='width:100%; margin-top:16px; margin-bottom:16px; background-color:#c45d4a; color:white;' rounded class="themed" :disabled="!validForms.changePassword" @click="requestPasswordChange">
                    <str index="buttons.send"/>
                </v-btn>

                <v-otp-input
                    v-if="validForms.changePassword"
                    v-model="password.pin"
                    type="number"
                    :length="config.pinLength"
                />
            </v-card-text>

            <!-- Submit button -->
            <v-card-actions>
                <!-- <v-spacer/> -->
                <v-btn style='width: 100%; margin-top: 16px; background-color:#c45d4a; color:white;' rounded class="themed" :disabled="password.pin==null || password.pin.length<config.pinLength" @click="verifyPasswordChange">
                    <str index="buttons.submit"/>
                </v-btn>
                <!-- <v-spacer/> -->
            </v-card-actions>

            <!-- Close button -->
            <v-card-actions style='position:absolute; top:8px; right:8px;'>
                <v-btn @click="ui.changePassword=false" color="rgba(0,0,0,0)" elevation='0' rounded small>
                    <v-icon left>mdi-close-circle</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- END Change Password dialog -->

    <v-snackbar v-model="ui.snackbar.display">
        <str v-if="ui.snackbar.display && ui.snackbar.message" :index="ui.snackbar.message"/>
        <template v-slot:action="{ attrs }">
            <v-btn
                color="pink"
                text
                v-bind="attrs"
                @click="ui.snarkbar.display = false"
            >
                <str index="buttons.close"/>
            </v-btn>
      </template>
    </v-snackbar>

</div>
</template>

<script>
import validations from '@/plugins/validations.js'
import allowAccess from '@/mixins/allowAccess.js'

export default {
    mixins: [allowAccess],        
    created: function(){
        // this.$store.commit('language',this.$store.getters.user.attributes['custom:language'])
        this.temp.language = this.$store.getters.user.attributes['custom:language']
        this.email.new = this.$store.getters.user.attributes.email.slice()
        this.validations = new validations.methods(this)
        this.rules = new validations.rules(this)
        this.init()

        window.addEventListener('resize', function(){
            let profileFormCard = document.querySelector(`.profileFormCard`);
                profileFormCard.scrollTop = 0;
        });
    },
    props: {
        queue: {
            type: Number,
            required: false,
            default: 0
        },
        language: {
            type: String,
            required: false,
            default: 'en-ca'
        }
    },
    data: function(){
        return {
            config: {
                pinLength: 6
            },
            busy: false,
            validations: null,
            rules: null,
            ui: {
                changeEmail: false,
                changePassword: false,
                snackbar: {
                    display: false,
                    message: null
                }
            },
            validForms: {
                moreInfo: false,
                changePassword: false
            },
            email: {
                new: null,
                pin: null
            },
            password: {
                new: null,
                confirmation: null,
                pin: null
            },
            user: null,
            db: {
                countries: [],
                states: [],
                cities: []
            },
            temp: {
                language: null
            }
        }
    },
    methods: {
        init: async function(){
            this.getCountries()
            this.getStates()
            this.getCities()
        },
        getCountries: async function(){
            async function getCountries(){
                /*
                let response
                try {
                    response = await self.sendRequest('get','/api/csc/getCountries')
                } catch (error) {
                    console.error('getCountries error:', error)
                }
                return response.status==200 ? response.data : []
                **/
                return [{code:"CA", name:"Canada", native:"Canada"}]       
            }

            let self = this

            let getCountriesResponse = await getCountries()
            self.db.countries = getCountriesResponse
        },
        getStates: async function(){
            async function getData(country){
                /*
                let response
                try {
                    response = await self.sendRequest('get',`/api/csc/getStates/${country}`)
                } catch (error) {
                    console.error('getStates error', error)
                }
                return response.status==200 ? response.data : []
                */
                return [
                    {"name":"Alberta","country_code":"CA","code":"AB"},
                    {"name":"British Columbia","country_code":"CA","code":"BC"},
                    {"name":"Manitoba","country_code":"CA","code":"MB"},
                    {"name":"New Brunswick","country_code":"CA","code":"NB"},
                    {"name":"Newfoundland and Labrador","country_code":"CA","code":"NL"},
                    {"name":"Northwest Territories","country_code":"CA","code":"NT"},
                    {"name":"Nova Scotia","country_code":"CA","code":"NS"},
                    {"name":"Nunavut","country_code":"CA","code":"NU"},
                    {"name":"Ontario","country_code":"CA","code":"ON"},
                    {"name":"Prince Edward Island","country_code":"CA","code":"PE"},
                    {"name":"Quebec","country_code":"CA","code":"QC"},
                    {"name":"Saskatchewan","country_code":"CA","code":"SK"},
                    {"name":"Yukon","country_code":"CA","code":"YT"}]   
            }

            let self = this
            let country = self.model.country
            if(typeof country=='string' && country.length>0){
                self.db.states = await getData(country)
            }else{
                console.error('getStates error: missing required data', country)
            }
        },
        getCities: async function(){
            return;
            async function getData(country, prov_state){
                let response
                try {
                    response = await self.sendRequest('get',`/api/csc/getCities/${country}/${prov_state}`)
                } catch (error) {
                    console.error('getCities error', error)
                }
                return response.status==200 ? response.data : []
            }

            let self = this
            let country = self.model.country
            let prov_state = self.model.prov_state
            if(((typeof country=='string' && country.length>0)) && (typeof prov_state=='string' && prov_state.length>0)){
                self.db.cities = await getData(country, prov_state)
            }else{
                console.error('getCities error: missing required data', {country, prov_state})
            }
        },
        requestEmailChange: async function(){
            this.$emit('queueChange','++')
            let response = await this.$store.getters.Authorizer.requestEmailChange(this.email.new)
            console.log('profile > requestEmailChange',response)
            this.ui.changeEmail = response
            this.$emit('queueChange','--')
        },
        verifyEmailChange: async function(){
            this.$emit('queueChange','++')
            let response = await this.$store.getters.Authorizer.verifyEmailChange(this.email.pin)
            console.log('profile > verifyEmailChange',response)
            this.ui.changeEmail = !response
            if(response){
                alert('Please login with your updated email')
                this.$store.getters.Authorizer.signOut()
            }
            this.$emit('queueChange','--')
        },
        requestPasswordChange: async function(){
            let username = this.username
            let response = await this.$store.getters.Authorizer.forgotPassword(username)
        },
        verifyPasswordChange: async function(){
            let self = this
            let username = this.username
            let code = this.password.pin
            let new_password = this.password.new

            let response = await this.$store.getters.Authorizer.resetPassword(username, code, new_password)
            if(response=='SUCCESS'){
                this.password.new = null
                this.password.confirmation = null
                this.password.pin = null
                this.ui.changePassword = false
                self.ui.snackbar.message = self.strMap.messages.change_password //"authenticator.edit_profile.message.change_password_success"
            }else{

                self.ui.snackbar.message = self.strMap.errors.change_password //"authenticator.edit_profile.message.change_password_failed"
            }
            self.ui.snackbar.display = true

        }
    },
    computed: {
        email_readonly: function(){
            return this.allowAccess(['SuperAdmin','admin','tester'])===false
        },
        username: function(){
            return this.$store.getters.user.attributes.email
        },
        model: function(){
            return this.$attrs.value
        },
        fields: function(){
            let fields = [
                // {
                //     target: 'given_name',
                //     label: 'forms.given_name',
                //     required: true,
                //     rules: this.rules.required,
                //     type: 'textfield'
                // },
                // {
                //     target: 'family_name',
                //     label: 'forms.family_name',
                //     required: true,
                //     rules: this.rules.required,
                //     type: 'textfield'
                // },
                // {
                //     target: 'practice_setting',
                //     label: 'forms.field.practice_setting',
                //     required: false,
                //     rules: this.rules.required,
                //     type: 'select',
                //     items: this.practice_setting
                // },
                {
                    target: 'affiliation',
                    label: 'forms.field.affiliation',
                    required: true,
                    rules: this.rules.required,
                    type: 'textfield'
                },
                {
                    target: 'timezone',
                    label: 'forms.field.timezone',
                    required: false,
                    rules: this.rules.required,
                    type: 'select',
                    items: this.timezoneItems
                },
                {
                    target: 'country',
                    label: 'forms.country',
                    required: false,
                    rules: this.rules.required,
                    type: 'autocomplete',
                    items: this.countries
                },
                {
                    target: 'prov_state',
                    label: 'forms.province',
                    required: false,
                    rules: this.rules.required,
                    type: 'autocomplete',
                    items: this.states
                },
                // {
                //     target: 'city',
                //     label: 'forms.city',
                //     required: false,
                //     rules: [],
                //     type: 'autocomplete',
                //     items: this.cities
                // },
                // {
                //     target: 'address',
                //     label: 'forms.address',
                //     required: false,
                //     rules: [],
                //     type: 'textfield'
                // },
                // {
                //     target: 'postal_zip',
                //     label: 'forms.postal_code',
                //     required: false,
                //     rules: [],
                //     type: 'textfield'
                // },
                {
                    target: 'hcp_job_profession',
                    label: 'forms.field.profession',
                    required: false,
                    rules: this.rules.required,
                    type: 'select',
                    items: this.professions
                },
                {
                    target: 'hcp_specialty',
                    label: 'forms.field.specialty',
                    required: false,
                    rules: this.rules.required,
                    type: 'select',
                    items: this.specialties
                },
                // {
                //     target: 'ctc_casl',
                //     label: 'forms.casl',
                //     required: false,
                //     rules: [],
                //     type: 'attest'
                // },
                {
                    target: 'is_accurate',
                    label: 'forms.is_accurate',
                    required: true,
                    rules: this.rules.required,
                    type: 'attest'
                }
            ]

            return fields
        },
        csc: function(){
            return {
                country: this.model.country,
                prov_state: this.model.prov_state,
                city: this.model.city
            }
        },
        countries: function(){
            let list = []
            let countries = this.db.countries
            for(let i=0; i<countries.length; i++){
                let country = countries[i]
                list.push({
                    text: country.name,
                    value: country.code
                })
            }
            return list
        },
        states: function(){
            let list = []
            let states = this.db.states
            for(let i=0; i<states.length; i++){
                let state = states[i]
                list.push({
                    text: state.name,
                    value: state.code
                })
            }
            return list
        },
        cities: function(){
            let list = []
            let cities = this.db.cities
            for(let i=0; i<cities.length; i++){
                let city = cities[i]
                list.push({
                    text: city.name,
                    value: city.name
                })
            }
            list.push({
                text: this.$store.getters.language=='fr-ca' ? 'Non listé' : 'Not listed',
                value: 'not_listed'
            })

            return list
        },
        professions: function(){
            let labels = this.labels
            let language = this.language

            let list = [
                {
                    text: labels?.forms?.profession?.physician?._text[language] ? labels.forms.profession.physician._text[language] : 'MISSING - labels.forms.profession.physician[language]',
                    value: 'physician'
                },
                {
                    text: labels?.forms?.profession?.pharmacist?._text[language] ? labels.forms.profession.pharmacist._text[language] : 'MISSING - labels.forms.profession.pharmacist[language]',
                    value: 'pharmacist'
                },
                {
                    text: labels?.forms?.profession?.patient_representative?._text[language] ? labels.forms.profession.patient_representative._text[language] : 'MISSING - labels.forms.profession.patient_representative[language]',
                    value: 'patient_representative'
                }
            ]


            return list
        },
        specialties: function(){
            let labels = this.labels
            let language = this.language

            let list = [
                {
                    text: labels?.forms?.profession?.dermatologist?._text[language] ? labels.forms.profession.dermatologist._text[language] : 'MISSING - labels.forms.profession.dermatologist[language]',
                    value: 'dermatologist'
                },
                {
                    text: labels?.forms?.profession?.allergist?._text[language] ? labels.forms.profession.allergist._text[language] : 'MISSING - labels.forms.profession.allergist[language]',
                    value: 'allergist'
                },
                {
                    text: labels?.forms?.profession?.rheumatologist?._text[language] ? labels.forms.profession.rheumatologist._text[language] : 'MISSING - labels.forms.profession.rheumatologist[language]',
                    value: 'rheumatologist'
                },
                {
                    text: labels?.forms?.profession?.pharmacist?._text[language] ? labels.forms.profession.pharmacist._text[language] : 'MISSING - labels.forms.profession.pharmacist[language]',
                    value: 'pharmacist'
                },
                {
                    text: labels?.forms?.profession?.other_na?._text[language] ? labels.forms.profession.other_na._text[language] : 'MISSING - labels.forms.profession.dermatolother_naogist[language]',
                    value: 'other_na'
                }

            ]


            return list
        },
        practice_setting: function(){
            let labels = this.labels
            let language = this.language

            let list = [
                {
                    text: labels?.forms?.practice_setting?.community?._text[language] ? labels.forms.practice_setting.community._text[language] : 'MISSING - labels.forms.practice_setting.community[language]',
                    value: 'community'
                },
                {
                    text: labels?.forms?.practice_setting?.hospital?._text[language] ? labels.forms.practice_setting.hospital._text[language] : 'MISSING - labels.forms.practice_setting.hospital[language]',
                    value: 'hospital'
                },
                {
                    text: labels?.forms?.practice_setting?.academia?._text[language] ? labels.forms.practice_setting.academia._text[language] : 'MISSING - labels.forms.practice_setting.academia[language]',
                    value: 'academia'
                }
            ]


            return list
        },
        timezoneItems: function(){
            return [
                {
                    text: "Eastern",
                    value: "Eastern"
                },
                {
                    text: "Atlantic",
                    value: "Atlantic"
                },
                {
                    text: "Pacific",
                    value: "Pacific"
                },
                {
                    text: "Mountain",
                    value: "Mountain"
                },
                {
                    text: "Central",
                    value: "Central"
                },
                {
                    text: "Newfoundland",
                    value: "Newfoundland"
                }
            ]
        },
        strMap: function(){
            return {
                'messages': {
                    'pin_sent': 'authenticator.messages.pin_sent',
                    'change_email': "authenticator.edit_profile.messages.change_email",
                    'change_password': "authenticator.edit_profile.messages.change_password",
                },
                'errors': {
                    'attempt_limit_exceeded': 'authenticator.errors.attempt_limit_exceeded',
                    'expiredCode': 'authenticator.errors.expiredCode',
                    'change_email': "authenticator.edit_profile.errors.change_email",
                    'change_password': "authenticator.edit_profile.errors.change_password"
                }
            }
        },
        labels: function(){
            return this.$store.getters.labels;
        },
        windowHeight: function(){
            return this.$store.getters.window.height
        }
    },
    watch: {
        csc: {
            deep: true,
            handler: function(after, before){
                if(after.country!=before.country){
                    this.getStates()
                }
                if(after.prov_state!=before.prov_state){
                    this.getCities()
                }
            }
        }
    }
}
</script>


<style lang="scss">

    #MoreInfo {

        .description {

            p {
                position: relative;
                color: black;
            }

            p:nth-of-type(1) {
                top: 24px;
            }

            p:nth-of-type(2) {
                top: 72px;
            }
        }
    }

    #ProfileForm {
        position: relative;
        display: flex;
        justify-content: center;
        height: 87vh;
        padding: 24px 0;
        background-color: rgba(0,0,0,0.5);
        margin-top: -8px;
        transition: margin-top 2s;
        animation: intro 0.2s ease-in-out;
        @keyframes intro {
            0% {
                margin-top: 24px;
            }
            100% {
                margin-top: -8px;
            }
        }

        @media(max-width: 575px){ // xs < 576
            margin-top: -8px;
            height: initial;
            padding: initial;
            animation: none;
            transition: none;
        }

        .button_profileForm {
            background-color: #c45d4a;
            color: white;
        }

        .profileFormCard {
            position: relative;
            overflow: hidden;
            width: 80%;
            background-color: white;
            border-radius: 16px;
            box-shadow: 0px 0px 4px 2px rgba(0,0,0,0.2) !important;
            padding: 32px 80px;

            @media(max-width: 991px){ // md < 992
                padding: 24px 40px;               
            }
            @media(max-width: 767px){ // xs < 576
                height: 75vh;
                overflow-y: auto;
            }
            @media(max-width: 575px){ // xs < 576
                width: 100%;
                height: 83vh;
                border-radius: 0;
                padding: 24px 16px 80px;
                box-shadow: none !important;
            }

            &:before {
                content: '';
                background-image: url('../../assets/CanClearLogo_leaf.png');
                background-size: contain;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 32px;
                left: -232px;
                opacity: 0.2;

                @media(max-width: 1199px){ // lg < 1200
                    top: 256px;
                    left: -120px;
                }
            }

            .fields {
                display: flex;

                @media(max-width: 767px){ // sm < 768
                    flex-flow: column;
                }

                .description {
                    width: 33%;
                    margin-top: 96px;
                    text-align: left;
                    z-index: 1;

                    p {
                        font-size: 48px;

                        &:nth-of-type(2){
                            margin-top: -24px;
                        }
                    }

                    @media(max-width: 1199px){ // lg < 1200
                        display: none;
                    }
                }

                .accountInfo {
                    width: 33%;

                    @media(max-width: 1199px){ // lg < 1200
                        width: 50%;
                    }
                    @media(max-width: 767px){ // sm < 768
                        width: 100%
                    }

                    .header {
                        margin: 16px 0;
                    }
                }

                .additionalInfo {
                    width: 33%;

                    @media(max-width: 1199px){ // lg < 1200
                        width: 50%;
                    }
                    @media(max-width: 767px){ // sm < 768
                        width: 100%
                    }

                    .header {
                        margin: 16px 0;
                    }
                }

                > div:not(:nth-of-type(1)) {
                    margin-left: 32px;

                    @media(max-width: 767px){ // sm < 768
                        margin-left: initial;
                    }
                }
            }

            .buttonsAndConfirmBox {

                .attest {
                    width: 100%;
                    padding-left: 34%;

                    @media(max-width: 1199px){ // lg < 1200
                        padding-left: initial;
                    }
                }

                .closeButton {
                    position: absolute;
                    top: 8px;
                    right: 8px;
                }

                .saveButton {
                    position: relative;
                    margin-left: 34%;
                    width: 280px;

                    @media(max-width: 1199px){ // lg < 1200
                        margin-left: initial;
                    }
                }
            }
        }
        // END profileFormCard
    }
    // END #ProfileForm
</style>
