<template>
  <div id="Home">
    <div v-if="state.ready">
      <div style="background-color: whitesmoke;">
        <v-container>
          <v-row>
            <v-col>
              <Title :config="{title:'events.upcoming',lineColor:'#1E58A1'}" :language="language"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class='event-container'>
                <template v-if="meetings.upcoming.length>0">
                  <template v-for="(meeting, index) in meetings.upcoming">
                    <EventCard v-if="meeting.publish=='Y'" @viewDetails="viewDetails" @launchPreMeetingSurvey="launchPreMeetingSurvey" :isUpcoming="true" :meeting="meeting" class="event-card" :key="`upcoming-meeting-card-${index}`"/>
                  </template>
                </template>
                <div v-else>
                  <str index="events.results.no_events_upcoming"/>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>


      <div style="background-color: #ededed;">
        <v-container>
          <v-row>
            <v-col>
              <Title :config="{title:'events.past',lineColor:'#98D0CA'}" :language="language"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class='event-container'>
                <template v-if="meetings.past.length>0">
                  <template v-for="(meeting, index) in meetings.past">
                    <EventCard @viewDetails="viewDetails" @launchPostMeetingSurvey="launchPostMeetingSurvey" :isUpcoming="false" :meeting="meeting" class="event-card" :key="`upcoming-meeting-card-${index}`"/>
                  </template>
                </template>
                <div v-else>
                  <str index="events.results.no_events_past"/>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>

    <div v-else>
      <center>Loading</center>
    </div>
  </div>
</template>

<script>
import Title from '@/components/Title/Title'
import EventCard from '@/components/Event/EventCard'
import MeetingSystem from '@/components/Event/eventSystem.js'
export default {
  name: 'Home',
  props: {
    language: {
      type: String,
      required: false,
      default: 'en-ca'
    }
  },
  components: {
    Title,
    EventCard
  },
  created: function(){
    this.meetingsAPI = new MeetingSystem(this)
    this.init()
  },
  data: function(){
    return {
      state: {
        ready: false
      },
      meetingsAPI: null
    }
  },
  methods: {
    init: async function(){
      this.state.ready = false
      let meetings = await this.meetingsAPI.getMeetings()
      this.$store.commit('meetings',meetings)
      this.state.ready = true
    },
    viewDetails: function(meeting){
      console.log('viewDetails',meeting)
      this.$router.push({name:'EventDetails',params:{event_id:meeting.id}})

    },
    launchPreMeetingSurvey: function(meeting){
      this.$router.push({name:'PreMeetingSurvey',params:{event_id:meeting.id}})
    },
    launchPostMeetingSurvey: function(meeting){
        this.$router.push({name:'PostMeetingSurvey',params:{event_id:meeting.id}})
    }
  },
  computed: {
    meetings: function(){
      let meetings = this.$store.getters.meetings
      let now = new Date()
      let output = {
        upcoming: [],
        past: []
      }
      for(let i=0; i<meetings.length; i++){
        let meeting = meetings[i]
        let end = new Date(meeting.end)
        if(end>=now){
          output.upcoming.push(meeting)
        }else{
          output.past.push(meeting)
        }
      }
      return output
    },
    meetingMap: function(){
      let meetings = this.$store.getters.meetings ? this.$store.getters.meetings : []
      let output = {}
      for(let i=0; i<meetings.length; i++){
        let meeting = meetings[i]
        output[meeting.id] = meeting
      }
      return output
    }
  }
}
</script>

<style scoped lang="scss">

#Home {
    .container {
        max-width: none; // REMOVE .container default
        padding: 0 6%;

        @media(max-width: 1399px){ // xl < 1400
            padding: 0 4%;
        }
    }
}

.event-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
}

.event-card{
    height: 200px;
    width: 400px;
}
</style>
