<template>
  <div id='MoreInfo' v-if="display">
    <!-- <v-dialog v-model="display" persistent> -->
        <v-card style='background-color:transparent; border-radius:none;'>
            <v-card-text>
                <Profile @updateProfile="updateProfile"/>
            </v-card-text>
        </v-card>
    <!-- </v-dialog> -->
  </div>
</template>

<script>
import Profile from '@/views/Profile.vue'
export default {
    // mounted: function(){
    //     let attributes = this.$store.getters.user.attributes
    //     let use = this.fieldMap
    //     for(let i=0; i<use.length; i++){
    //         let field = use[i]
    //         this.form[field.target] = attributes[field.key]
    //     }
    // },
    components: {
        Profile
    },
    created: async function(){
        // GET time of first login for user_id.
        // If the first login was within last 5 seconds
        // dispatch 'opened', else 'dispatch closed'
        /*
        let user_id        = this.$store.getters.user.username;
        let userFirstLogin = await this.getUserFirstLogin(user_id);
        let now            = Date.now();
        let difference     = now - userFirstLogin;
        let difference_sec = difference * 0.001;
        if( difference_sec <= 5){
            //this.$store.dispatch('profileFormState', 'opened');
        }
         * 
         */
    },
    data: function(){
        return {
            fieldMap: [
                {needed: true, target:'given_name',key:'given_name'},
                {needed: true, target:'family_name',key:'family_name'},
                {needed: true, target:'affiliation',key:'custom:affiliation'},
                {needed: true, target:'hcp_job_profession',key:'custom:hcp_job_profession'},
                {needed: true, target:'hcp_specialty',key:'custom:hcp_specialty'},
                {needed: true, target:'country',key:'custom:country'},
                {needed: true, target:'prov_state',key:'custom:prov_state'},
                {needed: true, target:'timezone',key:'custom:timezone'}
            ],
            // form: {
            //     given_name: null,
            //     family_name: null,
            //     affiliation: null,
            //     country: null,
            //     prov_state: null,
            //     hcp_job_profession: null,
            //     hcp_specialty: null,
            //     ctc_casl: 'false',
            //     timezone: 'Eastern'
            // }
        }
    },
    methods: {
        updateProfile: async function(response){
            if(response=='SUCCESS'){
                this.$store.getters.Authorizer.findUser()
            }
        },
        getUserFirstLogin: async function(user_id){
            return new Promise(async(resolve, reject)=>{
                this.sendRequest('GET', `/api/logger/${user_id}`).then((response)=>{
                    let result = response.data;
                    let userFirstLogin = result.loginRecords.length>0 ? Date.parse(result.loginRecords[0].timestamp) : Date.now();
                    resolve(userFirstLogin);
                });
            });
        }
    },
    computed: {
        display: function(){
            let display = false
            let fieldMap = this.fieldMap

            if(this.$store.getters.user){
                let attributes = this.$store.getters.user.attributes
                for(let i=0; i<fieldMap.length; i++){
                    let field = fieldMap[i]
                    if(field.needed && (!attributes[field.key] || attributes[field.key].length==0)){
                        display = true
                        this.$store.dispatch('profileFormState', 'opened');
                        this.$store.dispatch('profileFormFistLoginUpdateState', 'in-process');                        
                        break;
                    }
                }
            }
            return display
        }
    }
}
</script>

<style>

</style>
