import Vue from 'vue'
import VueMeta from 'vue-meta'
import { Amplify, Auth } from 'aws-amplify';
let cache = require('@/plugins/cache.js')
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import awsconfig from '@/components/Authorizer/config.js';
Amplify.configure({
    Auth: awsconfig.Auth
});

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  {
    path: '*',
    name: '404',
    component: () => import('../views/404.vue'),
    meta: {
      public: true,
      allowed: '*'
    }
  },
  {
    path: '/restricted',
    name: 'Restricted',
    component: () => import('../views/Restricted.vue'),
    meta: {
      public: true,
      allowed: '*'
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/Admin.vue'),
    children: [
      {
        path: '/admin/label',
        name: 'LabelAdmin',
        meta: {
          public: false,
          allowed: ['SuperAdmin','admin','staff','tester']
        },
        children: [
          {
            path: '/admin/label/:index',
            name: 'LabelAdminEditor',
            meta: {
              public: false,
              allowed: ['SuperAdmin','admin','staff','tester']
            }
          }
        ]
      }
    ],
    meta: {
      public: false,
      allowed: ['SuperAdmin','admin','staff','tester']
    }
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      public: false,
      allowed: '*'
    }
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('../views/terms_and_privacy.vue'),
    meta: {
      public: true,
      allowed: '*'
    }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('../views/terms_and_privacy.vue'),
    meta: {
      public: true,
      allowed: '*'
    }
  },
  {
    path: '/contact_us',
    name: 'ContactUs',
    component: () => import('../views/ContactUs.vue'),
    meta: {
      public: true,
      allowed: '*'
    }
  },
  {
    path: '/sign_in',
    name: 'SignIn',
    component: () => import('../views/SignInUp.vue'),
    meta: {
      public: true,
      allowed: '*'
    }
  },
  {
    path: '/sign_up/:check_in_code',
    name: 'SignUp',
    component: () => import('../views/SignInUp.vue'),
    meta: {
      public: true,
      allowed: '*'
    }
  },
  {
    path: '/account/profile',
    name: 'Profile',
    component: () => import('../views/Profile.vue'),
    meta: {
      public: false,
      allowed: '*'
    }
  },
  {
    path: '/resources',
    name: 'Resources',
    component: () => import('../views/Resources.vue'),
    meta: {
      public: false,
      allowed: '*'
    }
  },
  {
    path: '/discussionforum',
    name: 'DiscussionForum',
    component: () => import('../views/DiscussionForum.vue'),
    children: [
      {
        path: '/discussionforum/:topic_id',
        name: 'DiscussionForumTopic',
        meta: {
          name: 'DiscussionForumTopic',
          public: false,
          allowed: '*'
        }
      }
    ],
    meta: {
      public: false,
      allowed: '*'
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: {
      public: false,
      allowed: ['SuperAdmin','admin','tester','client']
    }
  },
  {
    path: '/dataDump',
    name: 'DataDump',
    component: () => import('../views/DataDump.vue'),
    meta: {
      public: false,
      allowed: ['SuperAdmin','admin','tester']
    }
  },
  {
    path: '/premeeting',
    component: () => import('../views/MeetingSurvey.vue'),
    children: [
      {
        path: '/premeeting/:event_id',
        name: 'PreMeetingSurvey',
        meta: {
          public: false,
          allowed: '*'
        }
      }
    ]
  },
  {
    path: '/postmeeting',
    component: () => import('../views/MeetingSurvey.vue'),
    children: [
      {
        path: '/postmeeting/:event_id',
        name: 'PostMeetingSurvey',
        meta: {
          public: false,
          allowed: '*'
        }
      }
    ]
  },
  {
    path: '/event',
    component: () => import('../views/Event.vue'),
    children: [
      {
        path: '/event/:event_id',
        name: 'EventDetails',
        meta: {
          public: false,
          allowed: '*'
        },
        children: [
          {
            path: '/event/:event_id/:tab',
            name: 'EventDetailsTab',
            meta: {
              public: false,
              allowed: '*'
            }
          }
        ]
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next)=>{
  if(to.meta.public){
    next()
  }else{
    // console.log('protected route',{
    //   allowed: to.meta.allowed
    // })
    // next()
    let findUser = async function(){
      try{
        const user = await Auth.currentAuthenticatedUser()
        let groups = user.signInUserSession.accessToken.payload['cognito:groups']
        let allow = to.meta.allowed.includes('*')

        if(typeof groups=='object' && groups.length!=undefined){
          for(let i=0; i<groups.length; i++){
            let group = groups[i]
            if( to.meta.allowed.includes(group) ){
              allow = true
              break;
            }
          }
        }

        if(allow){
          next()
        }else{
          next('/restricted')
        }
      }catch(err){
        console.error(err)
        cache.storage.local.set('pathAfterLogin',to.fullPath)
        next('/sign_in')
      }
    }

    findUser()
  }

})

export default router
