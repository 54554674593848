// src/mixins/postMeetingResources.js
export default {
        postMeetingRecordings: function(meetingResources, videoTypes=[]){
            return meetingResources.filter((item)=>{
                return !item.link && (item.category!==undefined && item.category==='post meeting' && item.typ!==undefined && item.typ==='recording')
                    && videoTypes.includes(item.asset.Key.substr(item.asset.Key.lastIndexOf('.')+1,item.asset.Key.length))
            })
        },
        postMeetingDocuments: function(meetingResources){
            return meetingResources.filter((item)=>{
                return !item.link && (item.category!==undefined && item.category==='post meeting' && (item.typ===undefined || item.typ!=='recording'))
            })
        }
};