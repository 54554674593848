<template>
  <v-card id="EventCard" :flat="flat" :class="{upcoming: daysUntilStart>=0, past: daysUntilStart<0 && daysAfterEventEnd>0}">
    <v-card-text>
      <div style="text-align: left;">
        <div style="cursor: pointer;" @click="$emit('viewDetails',meeting)">

          <div style="color:#c45d4a; font-size:16px;" v-if="daysUntilStart>=0">
            <v-icon small color="#c45d4a">
              mdi-radio-tower
            </v-icon>
            LIVE in
            <template v-if="daysUntilStart>=1">
              {{Math.round(daysUntilStart)}} days
            </template>
            <template v-else-if="daysUntilStart>=0">
              {{ Math.round(daysUntilStart*24) }} hour(s)
            </template>
          </div>
          <div style="color:#c45d4a; font-size:16px;" v-else-if="daysUntilStart<0 && daysAfterEventEnd<=0">
            <v-icon small color="#c45d4a">mdi-radio-tower</v-icon>
            LIVE NOW
          </div>

          <div class='titleLine'>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <strong v-html="meeting.title[language]" v-bind="attrs" v-on="on"/>
              </template>
              <p v-html="meeting.title[language]"/>
            </v-tooltip>
          </div>
          <div class='detailLine'>
            <v-icon small>
              mdi-calendar-blank
            </v-icon>
            <span class='textLine'>{{start.toDateString()}}</span>
          </div>
          <div class='detailLine'>
            <v-icon small>
              mdi-clock-time-five
            </v-icon>
            <span class='textLine'>{{Intl.DateTimeFormat('en-US', {hour12: false, hour: 'numeric', minute: 'numeric'}).format(start)}} - {{Intl.DateTimeFormat('en-US', {hour12: false, hour: 'numeric', minute: 'numeric', timeZoneName: 'short'}).format(end)}}</span>
          </div>
        </div>

        <div class='detailLine' style="cusor: pointer;" @click="$router.push({name:'EventDetailsTab',params:{event_id:meeting.id, tab: 'resources'}})">
          <v-icon small color="#c45d4a">
            mdi-file
          </v-icon>
          <span class='textLine'><a style="text-decoration:underline;">Pre-Meeting Materials</a></span>
        </div>

      </div>
    </v-card-text>


    <v-card-actions>
      <template v-if="daysAfterEventEnd<=0">
        <v-btn color="#c45d4a" v-if="!completedPreMeetingSurvey" :dark="allowAccess" small depressed rounded :disabled="!allowAccess" @click="$emit('launchPreMeetingSurvey',meeting)">Pre-Meeting Survey</v-btn>
        <v-btn color="success" v-else dark small depressed rounded>Pre-Meeting Survey <v-icon right>mdi-check</v-icon></v-btn>
      </template>
      <template v-else>
        <v-btn color="darkgrey" v-if="!completedPostMeetingSurvey" :dark="allowAccess" small depressed rounded :disabled="!allowAccess" @click="$emit('launchPostMeetingSurvey',meeting)">Post-Meeting Survey</v-btn>
        <v-btn color="success" v-else dark small depressed rounded>Post-Meeting Survey <v-icon right>mdi-check</v-icon></v-btn>
      </template>

      <template v-if="daysAfterEventEnd<=0">
        <v-btn v-if="meeting.category!=='in-person'" color="success" small depressed rounded :disabled="daysUntilStart>0.0105" :href="meeting.meetingLink" target="_blank">Join Now</v-btn>
        <v-tooltip top v-if="meeting.category==='in-person'">
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="mx-0 px-0" style="box-shadow: none;transform: rotateZ(15deg);" plain icon>
                    <v-icon large color="#c45d4a">mdi-handshake-outline</v-icon>
                </v-btn>                
            </template>
            <span>In-Person meeting</span>
        </v-tooltip>

        <v-btn icon @click="getICS">
          <v-icon>mdi-calendar-plus</v-icon>
        </v-btn>

      </template>
      <template v-if="daysAfterEventEnd>0">
        <v-tooltip top v-if="postMeetingRecordings.length===0">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mx-0 px-0" icon v-bind="attrs" v-on="on">
                <v-icon large disabled>mdi-play-box-outline</v-icon>
            </v-btn>
          </template>
          <span>Recording has not been released</span>
        </v-tooltip>
        <v-tooltip top v-if="postMeetingDocuments.length===0">
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="mx-0 px-0" icon v-bind="attrs" v-on="on"><v-icon size="32" disabled>mdi-file-document-outline</v-icon></v-btn>    
            </template>
          <span>Meeting notes has not been released</span>
        </v-tooltip>  
          
        <v-btn v-if="postMeetingRecordings.length>0" class="mx-0 px-0" style="box-shadow: none;" plain icon @click="$router.push({name:'EventDetailsTab',params:{event_id:meeting.id, tab: 'post_meeting_materials'}})">
            <v-icon large color="#c45d4a">mdi-play-box-outline</v-icon>
        </v-btn>
        <v-btn v-if="postMeetingDocuments.length>0" class="mx-0 px-0" style="box-shadow: none;" plain icon @click="$router.push({name:'EventDetailsTab',params:{event_id:meeting.id, tab: 'post_meeting_materials'}})">
            <v-icon color="#c45d4a" size="32">mdi-file-document-outline</v-icon>
        </v-btn>              
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>
import PostMeetingResources from '@/mixins/postMeetingResources.js'    
export default {  
  props: {
    flat: {
      type: Boolean,
      required: false,
      default: false
    },
    meeting: {
      type: Object,
      required: true
    },
    language: {
      type: String,
      required: false,
      default: 'en-ca'
    }
  },
  data: function(){
    return {
        videoTypes: ['mp4']
    }
  },
  methods: {
    getICS: async function(){
      let response = await this.sendRequest('get',`/api/meetings/ics/${this.meeting.id}`)
      //console.log(this.icsLink);
      //let response = await this.sendRequest('get',this.icsLink)
      console.log(response)
    }
  },
  computed: {
    postMeetingRecordings: function(){
        return PostMeetingResources.postMeetingRecordings(this.meeting.resources, this.videoTypes);
    },
    postMeetingDocuments: function(){
        return PostMeetingResources.postMeetingDocuments(this.meeting.resources);
    },
    icsLink: function(){
      return `${process.env.VUE_APP_API}/meetings/ics/${this.meeting.id}`
    },
    daysUntilStart: function(){
      let start = this.start
      let now = new Date()
      let diff = (start-now) /1000/60/60/24
      let days = diff
      return days
    },
    daysAfterEventEnd: function(){
      let end = this.end
      let now = new Date()
      return  (now-end) /1000/60/60/24
    },
    start: function(){
      return new Date(this.meeting.start)
    },
    end: function(){
      return new Date(this.meeting.end)
    },
    completedPreMeetingSurvey: function(){
      let meeting = this.meeting
      let preMeetingAnswers = this.$store.getters.preMeetingAnswers
      let completed = preMeetingAnswers.filter((submission)=>{return submission.question_set_id==meeting.preMeetingSurvey && submission.event_id==meeting.id}).length>0
      return completed
    },
    completedPostMeetingSurvey: function(){
      let meeting = this.meeting
      let postMeetingAnswers = this.$store.getters.postMeetingAnswers
      let completed = postMeetingAnswers.filter((submission)=>{return submission.question_set_id==meeting.postMeetingSurvey && submission.event_id==meeting.id}).length>0
      return completed
    },
    allowAccess: function(){
        let meeting = this.meeting

        let rights = this.$store.getters.rights
        let allowed = ['SuperAdmin','admin','staff','testers']
        for(let i=0; i<rights.length; i++){
            let group = rights[i]
            if(allowed.includes(group)){
                return true
            }
        }

        if(meeting.preMeetingSurvey && this.daysAfterEventEnd<=0){
          return true
        }

        if(meeting.postMeetingSurvey && this.daysAfterEventEnd>0){
          return true
        }

        return false
    },
    event_id: function(){
        return this.$route.params.event_id
    },
    // surveyItems: function(){
    //     let questionSetData = this.questionSetData
    //     let output = []
    //     if(questionSetData){
    //         output = [
    //             {text: 'Dev', stage: 'dev', value: questionSetData.dev_id, disabled: questionSetData.dev_id ? false : true},
    //             {text: 'QA', stage: 'qa', value: questionSetData.qa_id, disabled: questionSetData.qa_id ? false : true},
    //             {text: 'Prod', stage: 'prod', value: questionSetData.prod_id, disabled: questionSetData.prod_id ? false : true},
    //         ]
    //     }

    //     return output
    // }
  }
}
</script>

<style lang='scss' scoped>
::v-deep .v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover) .v-btn__content {
    opacity: unset !important;
}

#EventCard {
    padding: 24px 0px 24px 24px;
    height: fit-content;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 4px solid;
    overflow-x: hidden;
    margin-bottom: 16px;

    @media(max-width: 767px){ // xs < 576
        margin-bottom: 8px;
    }


    // Over-ride vuetify defaults
    .v-card__text {
        padding: 0;
    }
    .v-card__actions {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin-top: 16px;

        .v-btn {
            margin: 0 8px 8px 0;
            padding: 5px 15px 5px 15px;
            font-size: 0.7rem
        }
    }

    // classes for differentiated left border colours
    &.upcoming {
        border-color: $orange !important;

        &:before {
            top: 58px;
            background-color: $orange;
        }
    }
    &.past {
        border-color: #CCC !important;

        &:before {
            top: 36px;
            background-color: #CCC;
        }
    }
    &.upcoming,
    &.past {

        &:before {
            content: '';
            position: absolute;
            left: -6px;
            height: 12px;
            width: 12px;
            transform: rotate(45deg);
        }
    }
    // CASE for LIVE NOW (not upcoming && not past)
    &:not(.upcoming):not(.past) {
        border-color: $orange !important;
        //min-height: 243.5px;

        &:before {
            content: '';
            position: absolute;
            top: 58px;
            left: -6px;
            height: 12px;
            width: 12px;
            transform: rotate(45deg);
            background-color: $orange;
        }
    }

    .titleLine {
        width: 100%;
        margin-top: 8px;
        margin-bottom: 8px;
        margin-left: 1px;
        font-size: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .detailLine {
        margin-top: 4px;

        .textLine {
            margin-top: 2px;
            margin-left: 8px;
            font-size: 16px;

            @media(max-width: 575px){ // xs < 576
                font-size: 14px;
            }
        }
    }
}
</style>
