<template>
  <!-- <div class="app-footer" :class="[{'signed-in':$store.getters.user!=undefined}]">
    <v-container fluid>
        <v-row style='padding: 8px;'>
            <v-col cols="4"></v-col>
            <v-col cols="4">
                <str index="footer.all_rights" style='font-size: 14px;'/>
            </v-col>
            <v-col cols="4">
                <div style="position: relative; top: -5px;">
                    <v-btn :dark="$store.getters.user!=undefined" @click="$router.push({name:'Terms'})" text>
                        <str index="footer.terms_of_use.link" style='text-transform:none;'/>
                    </v-btn>
                    |
                    <v-btn :dark="$store.getters.user!=undefined" @click="$router.push({name:'Privacy'})" text>
                        <str index="footer.privacy_policy.link" style='text-transform:none;'/>
                    </v-btn>
                </div>
            </v-col>
        </v-row>
    </v-container>
  </div> -->

    <div class="app-footer" :class="[{'signed-in':$store.getters.user!=undefined}]">
        <div class="rights">
            <str index="footer.all_rights" style='margin-bottom:0 !important;'/>
        </div>
        <div class="termsAndPrivacy">
            <v-btn :dark="$store.getters.user!=undefined" @click="routeTo('Terms')" text>
                <str index="footer.terms_of_use.link" style='text-transform:none;'/>
            </v-btn>
            |
            <v-btn :dark="$store.getters.user!=undefined" @click="routeTo('Privacy')" text>
                <str index="footer.privacy_policy.link" style='text-transform:none;'/>
            </v-btn>
        </div>


        <!-- <v-container fluid>
            <v-row style='padding: 8px;'>
                <v-col cols="4"></v-col>
                <v-col cols="4">
                    <str index="footer.all_rights" style='font-size: 14px;'/>
                </v-col>
                <v-col cols="4">
                    <div style="position: relative; top: -5px;">
                        <v-btn :dark="$store.getters.user!=undefined" @click="$router.push({name:'Terms'})" text>
                            <str index="footer.terms_of_use.link" style='text-transform:none;'/>
                        </v-btn>
                        |
                        <v-btn :dark="$store.getters.user!=undefined" @click="$router.push({name:'Privacy'})" text>
                            <str index="footer.privacy_policy.link" style='text-transform:none;'/>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-container> -->

    </div>

</template>

<script>
export default {
    methods: {
        routeTo: function(routeName){
            if(this.$store.getters.profileFormState === 'opened'){
                return;
            }
            this.$router.push({name:routeName})
        }
    }, 
    computed: {
    }
}
</script>

<style scoped lang="scss">
.app-footer{
    width: 100%;
    height: 40px;
    background-color: whitesmoke;
    color: slategray;
    border-top: 1px solid lightgrey;
    display: flex;
    position: relative;
    padding: 8px;

    @media(max-width: 1199px){ // lg < 1200
        justify-content: space-around;
    }
    @media(max-width: 767px){ // sm < 768
        flex-flow: column-reverse;
        height: auto;
        padding: 0;
    }

    .rights {
        width: 100%;
        font-size: 14px;
        position: relative;
        top: 2px;
        padding: 0 8px;

        @media(max-width: 1199px){ // lg < 1200
            width: initial;
        }

        p {
            margin-bottom: 0px !important; // reset margin bottom on p
        }
    }

    .termsAndPrivacy {
        position: absolute;
        right: 0;
        top: 2px;

        @media(max-width: 1199px){ // lg < 1200
            position: relative;
            top: -5px;
        }
        @media(max-width: 767px){ // sm < 768
            top: 0;
        }

        .v-btn {
            color: slategray;
            font-size: 13px;
        }
    }
}
</style>
