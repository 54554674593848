<template>
<div id="Title">
    <div :class="alignmentClasses">
        <div v-if="title">
            <div class='title'>
                <str :index="title" :class="config.title == 'events.upcoming' ? 'orangeColor' : '' "/>
            </div>
            <div class="titleDecoration">
                <img src="../../assets/CanClearLogo_leaf.png" alt="">
            </div>
        </div>
        <div class="section-subtitle" v-if="subtitle">
            <str :index="subtitle"/>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        config: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            reuqired: true
        },
        color: {
            type: String,
            required: false
        }
    },
    computed: {
        title: function(){
            let language = this.language
            let config = this.config
            return config.title //&& config.title[language] ? config.title[language] : null
        },
        subtitle: function(){
            let language = this.language
            let config = this.config
            return config.subtitle //&& config.subtitle[language] ? config.subtitle[language] : null
        },
        alignmentClasses: function(){
            let output = []
            let align = this.config.align ? this.config.align : {}
            if(this.config.dense){
              output.push('dense')
            }
            for(let axis in align){
                let position = align[axis]
                output.push(`${axis}-align-${position}`)
            }

            return output
        }
    }
}
</script>

<style scoped lang="scss">

#Title {

    .orangeColor {
        color: $orange;
    }
    .brown_dark {
        color: $brown_dark;
    }

    .title {
        margin: 16px 0;
        text-align: center;
        font-size: 22px !important;

        @media(max-width: 767px){ // sm < 768
            font-size: 20px !important;
        }
    }

    .titleDecoration {
        position: relative;
        width: 40px;
        margin: 0 auto;

        img {
            width: 100%;
        }

        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 80px;
            height: 2px;
            top: 50%;
            background-color: $orange;
        }

        &:before {
            left: calc(-80px - 24px);
        }

        &:after {
            right: calc(-80px - 24px);
        }
    }

    .section-subtitle{
      padding: 15px;
      margin: 15px;
    }
}
</style>
