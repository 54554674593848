import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    debug: false,
    Authorizer: undefined,
    language: 'en-ca',
    languages: ['en-ca'],
    labels: undefined,
    exposeLabels: false,
    user: undefined,
    pathAfterLogin: null,
    meetings: undefined,
    preMeetingAnswers: [],
    postMeetingAnswers: [],
    profileFormState: null,
    profileFormFistLoginUpdateState: null,
    window: {
      height: null,
      width: null
    }
  },
  getters: {
    Authorizer: function(state){
      return state.Authorizer
    },
    pathAfterLogin: function(state){
      return state.pathAfterLogin
    },
    profileFormState: function(state){
        return state.profileFormState
    },
    profileFormFistLoginUpdateState: function(state){
        return state.profileFormFistLoginUpdateState
    },
    debug: function(state){
      return state.debug
    },
    labels: function(state){
      return state.labels
    },
    exposeLabels: function(state){
      return state.exposeLabels
    },
    language: function(state){
      return state.language
    },
    languages: function(state){
      return state.languages
    },
    user: function(state){
      return state.user
    },
    meetings: function(state){
      return state.meetings
    },
    preMeetingAnswers: function(state){
      return state.preMeetingAnswers
    },
    postMeetingAnswers: function(state){
      return state.postMeetingAnswers
    },
    window: function(state){
      return state.window
    },
    rights: function(state){
      return state.user && state.user.signInUserSession.accessToken.payload['cognito:groups'] ? state.user.signInUserSession.accessToken.payload['cognito:groups'] : []
    }
  },
  actions: {
    Authorizer: function(context, payload){
      context.commit('Authorizer',payload)
    },
    pathAfterLogin: function(context, payload){
      context.commit('pathAfterLogin',payload)
    },
    profileFormState: function(context, payload){
        context.commit('profileFormState',payload)
    },
    profileFormFistLoginUpdateState: function(context, payload){
        context.commit('profileFormFistLoginUpdateState',payload)
    },
    debug: function(context, payload){
      context.commit('debug',payload)
    },
    labels: function(context, payload){
      context.commit('labels',payload)
    },
    exposeLabels: function(context, payload){
      context.commit('exposeLabels',payload)
    },
    language: function(context, payload){
      context.commit('language',payload)
    },
    languages: function(context, payload){
      context.commit('languages',payload)
    },
    user: function(context, payload){
      context.commit('user',payload)
    },
    meetings: function(context, payload){
      context.commit('meetings',payload)
    },
    preMeetingAnswers: function(context, payload){
      context.commit('preMeetingAnswers',payload)
    },
    preMeetingAnswers: function(context, payload){
      context.commit('preMeetingAnswers',payload)
    },
    height: function(context, payload){
      context.commit('height',payload)
    },
    width: function(context, payload){
      context.commit('width',payload)
    }
  },
  mutations: {
    Authorizer: function(state, payload){
      state.Authorizer = payload
    },
    pathAfterLogin: function(state, payload){
      state.pathAfterLogin = payload
    },    
    profileFormState: function(state, payload){
        state.profileFormState = payload
    },
    profileFormFistLoginUpdateState: function(state, payload){
        state.profileFormFistLoginUpdateState = payload
    },
    debug: function(state, payload){
      state.debug = payload
    },
    labels: function(state, payload){
      state.labels = payload
    },
    exposeLabels: function(state, payload){
      state.exposeLabels = payload
    },
    language: function(state, payload){
      state.language = payload
    },
    languages: function(state, payload){
      state.languages = payload
    },
    user: function(state, payload){
      state.user = payload
    },
    meetings: function(state, payload){
      state.meetings = payload
      // let meetings = []
      // for(let i=0; i<payload.length; i++){
      //   let meeting = payload[i]
      //   let allow = true
      //   for(let r=0; r<meeting.roles.length; r++){
      //     let users = meetings.roles[r]
      //     if(users.length!=0){
      //       allow = false
      //     }
      //   }
      //   for(let r=0; r<meeting.roles.length; r++){
      //     let users = meetings.roles[r]
      //     if(users.filter((username)=>{return username==state.user.username}).length>0){
      //       allow = true
      //     }
      //   }
      //   console.log({meeting, user: state.user, allow})
      //   meetings.push(meeting)
      // }
      // state.meetings = meetings
    },
    preMeetingAnswers: function(state, payload){
      state.preMeetingAnswers = payload
    },
    postMeetingAnswers: function(state, payload){
      state.postMeetingAnswers = payload
    },
    height: function(state, payload){
      state.window.height = payload
    },
    width: function(state, payload){
      state.window.width = payload
    }
  },
  modules: {
  }
})
