// src/mixins/allowAccess.js
export default {
    methods: {
        allowAccess: function(allowed=['']){
            if (allowed.length==1 && allowed[0]==='any'){
                return true;
            }
            let payload = this.$store.getters.user.signInUserSession.accessToken.payload['cognito:groups']
            if(payload){
                return payload.filter((item)=>{return allowed.includes(item)}).length>0
            }
            return false
        }
    }
};