import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import cache from "./plugins/cache.js";
import vuetify from '@/plugins/vuetify' // path to vuetify export
import JsonViewer from "vue-json-viewer"
import axios from 'axios'

import commonComponents  from "./components/Globals/loader";
commonComponents.forEach(component => {
  Vue.component(component.name, component);
}); 

Vue.config.productionTip = false
Vue.use(JsonViewer)

let code = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

if(location.hostname=="localhost"){
  window.code = code;
}
