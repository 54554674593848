<template>
  <div class="app-header">
    <v-container fluid v-if="$store.getters.user">
        <v-row>
            <v-col cols="1" style="text-align: left;">
                <v-btn text @click="$router.push({name:'Home'})">
                    <v-img :src="images.Logo" style="width: 180px;" class='logoInHeader'/>
                    <!-- <v-img :src="images.Logo" style="background-size: contain;"/> -->
                </v-btn>
            </v-col>
            <v-col cols="11" style="text-align: right;" v-if="$route.name!='Admin'">

                <div class="desktop">
                    <template  v-for="(item, index) in items">
                        <v-hover v-slot="{ hover }" v-if="allowAccess(item.allowed_roles)">
                        <v-btn text @click="link(item)"  :color="hover ? '#c45d4a' : ''" class="menu-item" :class="[{'active':item.link==$route.name}]" :key="`menu-${index}`">
                                <str :index="item.label"/>
                        </v-btn>
                        </v-hover>
                    </template>
                </div>

                <div class="mobile">
                    <v-menu v-if="$store.getters.user" offset-y>
                        <template v-slot:activator="{on, attrs}">
                            <v-btn large icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-menu</v-icon>
                            </v-btn>
                        </template>
                        <v-list style="text-align: left;">
                            <v-list-item text @click="link(item)" class="menu-item" :class="[{'active':item.link==$route.name}]" v-for="(item, index) in items" :key="`menu-mobile-${index}`">
                                <v-list-item-content>
                                    <str :index="item.label"/>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>

                <v-menu v-if="$store.getters.user" offset-y>
                    <template v-slot:activator="{on, attrs}">
                        <v-btn large icon v-bind="attrs" v-on="on">
                        <v-avatar style='background-color:#c45d4a; color:white;' size="36px">
                            {{$store.getters.user.attributes.given_name.substr(0,1)}}{{$store.getters.user.attributes.family_name.substr(0,1)}}
                        </v-avatar>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-if="$store.getters.profileFormState !== 'opened'">
                        <v-icon>mdi-account</v-icon>
                        <!-- <v-btn text @click="$router.push({path:'/account/profile'})">My Account</v-btn> -->
                        <v-btn @click="activeProfileForm=true;$store.dispatch('profileFormState', 'opened')" text>My Account</v-btn>
                        </v-list-item>

                        <hr>
                        <template v-if="allowAccess(['SuperAdmin','admin','staff','tester'])">
                            <v-list-item>
                                <v-icon>
                                    mdi-account-tie-hat
                                </v-icon>
                                <v-btn text @click="$router.push({path:'/admin'})">Admin Panel</v-btn>
                            </v-list-item>
                            <hr>
                            <v-list-item>
                                <v-icon>
                                    mdi-account-tie-hat
                                </v-icon>
                                <v-btn text @click="$router.push({path:'/dataDump'})">Data Dump</v-btn>
                            </v-list-item>
                            <hr>
                            <v-list-item>
                                <v-icon :color="$store.getters.exposeLabels ? 'green' : 'grey'">
                                    mdi-application-edit
                                </v-icon>
                                <v-btn text @click="$store.commit('exposeLabels',!$store.getters.exposeLabels)">Edit Labels</v-btn>
                            </v-list-item>
                        </template>

                        <v-list-item>
                            <v-icon>
                                mdi-exit-to-app
                            </v-icon>
                            <v-btn text @click="$store.getters.Authorizer.signOut">Sign Out</v-btn>
                        </v-list-item>

                    </v-list>
                </v-menu>
            </v-col>
        </v-row>
    </v-container>

    <!-- ProfileForm -->
    <ProfileForm v-if="activeProfileForm==true" v-model="form" @updateProfile="updateProfile" :queue="queue" @queueChange="queueChange" @EMIT_activeProfileForm="(given)=>this.activeProfileForm = given"/>
    <v-snackbar v-model="ui.snackbar.display">
        <str v-if="ui.snackbar.message" :index="ui.snackbar.message"/>
        <template v-slot:action="{ attrs }">
            <v-btn v-bind="attrs" @click="ui.snackbar.display=false" color="pink" text>
                <str index="buttons.close"/>
            </v-btn>
        </template>
    </v-snackbar>
    <!-- End ProfileForm -->

  </div>
  <!-- END app-header -->
</template>

<script>
import Logo from '@/assets/logo.png'
import allowAccess from '@/mixins/allowAccess.js'
import ProfileForm from '@/components/Authorizer/ProfileForm.vue'
export default {
    components: {
        ProfileForm
    },
    mixins: [allowAccess],
    mounted: function(){
        let attributes = this.$store.getters.user.attributes
        let use = this.fieldMap
        for(let i=0; i<use.length; i++){
            let field = use[i]
            this.form[field.target] = attributes[field.key]
        }       
    },
    data: function(){
        return {
            activeProfileForm: false,
            queue: 0,
            ui: {
                snackbar: {
                    display: false,
                    message: null
                }
            },
            fieldMap: [
                {target:'given_name',key:'given_name'},
                {target:'family_name',key:'family_name'},
                {target:'affiliation',key:'custom:affiliation'},
                {target:'hcp_job_profession',key:'custom:hcp_job_profession'},
                {target:'hcp_specialty',key:'custom:hcp_specialty'},
                {target:'hubspot_id',key:'custom:hubspot_id'},
                {target:'country',key:'custom:country'},
                {target:'prov_state',key:'custom:prov_state'},
                {target:'timezone',key:'custom:timezone'},
                {target:'hcp',key:'custom:hcp'},
                {target:'is_accurate',key:'custom:is_accurate'},
                {target:'language',key:'custom:language'}
            ],
            form: {
                given_name: null,
                family_name: null,
                affiliation: null,
                country: null,
                prov_state: null,
                hcp_job_profession: null,
                hcp_specialty: null,
                hubspot_id: null,
                timezone: 'Eastern',
                hcp: 'N',
                is_accurate: 'N',
                language: null
            }
        }
    },
    methods: {
        link: function(item){
            // prevent user from clicking links when initial profileForm is open
            if (this.$store.getters.profileFormState==='opened'){
                return;
            };

            if(item.link){
                this.$router.push({name:item.link})
            }
        },
        updateProfile: async function(data){
            this.queue++
            let form = this.form
            let fieldMap = this.fieldMap
            let customData = {}
            for(let i=0; i<fieldMap.length; i++){
                let field = fieldMap[i]
                customData[field.key] = form[field.target]
            }
            let response = await this.$store.getters.Authorizer.updateAttribute(customData)
            await this.sendRequest('patch','/api/profile',customData)
            if(response=='SUCCESS'){
                this.ui.snackbar.message = 'messages.success.saved'
            }else{
                this.ui.snackbar.message = 'messages.errors.not_saved'
            }
            this.ui.snackbar.display = true
            this.$emit('updateProfile',response)
            this.queue--
        },
        queueChange: function(ask){
            if(ask=='++'){this.queue++}
            if(ask=='--'){this.queue--}
        },
        busy: function(){
            return this.queue>0
        }
    },
    computed: {
        images: function(){
            return {
                Logo
            }
        },
        items: function(){
            return [
                {
                    label: 'header.buttons.home',
                    link: 'Home',
                    allowed_roles: ['any']
                },
                {
                    label: 'header.buttons.resources',
                    link: 'Resources',
                    allowed_roles: ['any']
                },
                {
                    label: 'header.buttons.discussion_forum',
                    link: 'DiscussionForum',
                    allowed_roles: ['any']
                },
                {
                    label: 'header.buttons.contact_us',
                    link: 'ContactUs',
                    allowed_roles: ['any']
                },
                {
                    label: 'header.buttons.dashboard',
                    link: 'Dashboard',
                    allowed_roles: ['SuperAdmin','admin','tester','client']
                }
            ]
        },
    },
    watch: {
        queue: function(){
            if(this.queue<0){
                this.queue=0
            }
        }
    }
}
</script>

<style scoped lang="scss">
.app-header{
    width: 100%;
    height: 60px;
    background-color: whitesmoke;
    border-bottom: 1px solid;
    border-color: lightgrey;
}

.active{
    font-weight: bold;
    color: #c45d4a !important;
}

.desktop{
    display: inline-block;
}

.mobile{
    display: none;
}

@media only screen and (max-width: 1024px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: inline-block;;
  }
}
</style>

<style lang="scss">
.app-header {
    .logoInHeader {
        .v-image__image--cover{
            background-size: contain !important;
        }
    }
}
</style>
