<template>
  <v-app class="app">
    <div>
      <Authorizer @ready="readyUp" @signedIn="signedIn" @signedOut="signedOut"/>
      <str index="test" :init="true"/>
      <v-main class="app-main" v-if="state.authorizer=='ready'">
        <Header v-if="route!=='SignIn' && user!==undefined" style="z-index: 200; position: fixed; top: 0px;"/>
        <div id="app-body">
          <Banner v-if="displayBanner"/>
          <router-view/>
        </div>
        <Footer style="z-index: 200; position: fixed; bottom: 0px;"/>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import Vue from "vue";
import axios from "axios"
import Authorizer from '@/components/Authorizer/Authorizer'

import Header from '@/components/Header/Header'
import Banner from '@/components/Banner/Banner'
import Footer from '@/components/Footer/Footer'
export default {
  name: 'App',
  components: {
    Authorizer,
    Header,
    Banner,
    Footer
  },
  created: async function(){
    let self = this
    Vue.prototype.sendRequest = self.sendRequest
    window.addEventListener('resize', this.resizeBody);
  },
  destroyed() {
      window.removeEventListener('resize', this.resizeBody);
  },
  data: function(){
    return {
      state: {
        authorizer: 'idle'
      },
    }
  },
  methods: {
    sendRequest: function(method='get', url, data=null){
      let self = this
      return new Promise((resolve, reject)=>{
        if(self.$store.getters.user){// && url.indexOf('/admin/')>0
          axios.defaults.headers['Authorization'] = `Bearer ${self.$store.getters.user.signInUserSession.idToken.jwtToken}`
        }else{
          delete axios.defaults.headers['Authorization']
        }
        if(process.env.NODE_ENV!='development'){
          // console.log('sendRequest',{url,data,env: process.env})
          url = url.replace('/api',process.env.VUE_APP_API)
        }
        if(method=='upload'){
          axios.defaults.headers['Content-Type'] = 'multipart/form-data'
          method = 'put'
        }else{
          delete axios.defaults.headers['Content-Type']
        }
        axios({
          method,
          url,
          data
        }).then((response)=>{
          if(response.headers['content-type']=="text/calendar; charset=utf-8"){
            let filename = "meeting.ics"
            if(filename){
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', filename);
              link.setAttribute('id','download-link')
              document.body.appendChild(link);
              link.click();
              link.remove();
            }

          }
          resolve(response)
        },(error)=>{
          resolve(error)
        })
      });
    },
    readyUp: function(){
        let self = this
        self.state.authorizer='ready'
        setTimeout(() => {
           console.log('readyUp=>resizeBody')
           self.resizeBody()
        }, 200)
    },
    resizeBody: function(){
      let headerHeight = document.getElementsByClassName('app-header')[0]!==undefined ? document.getElementsByClassName('app-header')[0].clientHeight : 0
      let footerHeight = document.getElementsByClassName('app-footer')[0].clientHeight
      let width = window.innerWidth
      let height = window.innerHeight
      this.$store.commit('width',width)
      this.$store.commit('height',height)
      let appBody = document.getElementById('app-body')
      appBody.style.marginTop = `${headerHeight}px`
      appBody.style.paddingBottom = `${footerHeight}px`;
    },
    signedIn: async function(){
      let user = this.$store.getters.user
      let hubspot_id = user && user.attributes && user.attributes['custom:hubspot_id'] ? user.attributes['custom:hubspot_id'] : null
      if(hubspot_id && hubspot_id.length>0){
        let response = await this.sendRequest('put',`/api/admin/meetings/propagate/${hubspot_id}`)
        //console.log('signedIn', {user, response})
        let self = this
        setTimeout(() => {
           console.log('signedIn=>resizeBody')
           self.resizeBody()
        }, 200);
      }
    },
    signedOut: function(){
      console.log('signedOut')
    }
  },
  computed: {
    displayBanner: function(){
      let routeName = this.$route.name
      let allow = ['Home','Resources']
      return allow.includes(routeName)
    },
    user: function(){
      return this.$store.getters.user
    },
    route: function(){
        return this.$route.name
    }
  },
  watch: {
    user: function(){
      if(!this.user){
        this.$router.push({name:"SignIn"})
      }
    },
    route: function(){
        this.activeTab = this.route
        let elem = document.getElementById('app-body')
        if(elem){
          elem.scrollTop=0
        }
    }
  }
}

</script>
<style lang="scss">

html,
body {
    overflow-x: hidden !important;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: $brown_dark;
}

#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}

p {
    font-size: 16px;
}

.title {
    color: $brown_dark;
    text-align: left;
    text-transform: uppercase;
    font-size: 22px !important;

    @media(max-width: 767px){ // sm < 768
        font-size: 20px !important;
    }
}

.tox.tox-tinymce-aux {
    z-index: 4000;
}

.v-btn__content p{
    margin: 0 !important;
}

// All .v-dialog should have similar style.
// -- border-radius on v-dialog
// -- padding on the v-card
// -- reset/zero padding on elements within card, as they are varied. The padding on v-card provides padding consistency.
// The rules should to be here because v-dialog is appended to the same level as .v-application--wrap
.v-dialog {
    border-radius: 16px !important;

    .changePasswordDialog {
        overflow-y: hidden;
    }

    .v-card {
        padding: 24px;

        .v-card__title {
            padding: 0 !important;
        }

        .v-card__text {
            padding: 0 !important;
        }

        .v-card__actions {
            padding: 0 !important;
        }
    }
}
</style>
