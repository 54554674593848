<template>
  <div>
    <MoreInfo id='MoreInfo' v-if="$store.getters.labels && $store.getters.user"/>
    <!-- // 1-866-513-2059 -->
  </div>
</template>
<script>
import { Amplify, Auth, Hub } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import awsconfig from './config.js';

Amplify.configure({
    Auth: awsconfig.Auth
});

// You can get the current config object
const currentConfig = Auth.configure();
import MoreInfo from './MoreInfo.vue'
export default {
    components: {
        MoreInfo
    },
    created: async function(){
        await this.findUser()
        Hub.listen('auth', (data) => {
            if(this.debug){
                console.log('Hub response',data)
            }
            const { payload } = data;
            this.hubEvent = payload

            if(payload.event=='autoSignIn'){
                const user = payload.data
                this.$store.commit('user',user)
            }

            if(payload.event=='signIn'){
                this.findUser()
            }else if(payload.event=='signIn_failure'){
                if(payload.data.code=='UserNotConfirmedException'){

                }
            }

            if(payload.event=='signOut'){
                this.findUser()
            }else if(payload.event=='signOut_failure'){

            }

            if(payload.event=='signUp'){

            }else if(payload.event=='signUp_failure'){

            }

            if(payload.event=='signUp_failure'){
                if(payload.data.code=="UsernameExistsException"){
                    console.log('debug - signUp_failure',payload)
                }else{

                }
            }
        })
        this.$store.commit('Authorizer', this)
        this.$emit('ready')

        let self = this
        let elem = document.getElementById('app')
        elem.addEventListener('click',function(event){
            self.session.inactivity = 0
        })
        window.addEventListener('scroll',function(event){
            self.session.inactivity = 0
        })
        window.addEventListener('keydown',function(event){
            self.session.inactivity = 0
        })
        self.trackInactivity()
        self.sessionHandler()

    },
    data: function(){
        return {
            // user: undefined,
            hubEvent: null,
            session: {
                expires: null,
                time_left: null,
                inactivity: 0,
                inactivity_timer: null
            },
            temp: {
                user: null
            }
        }
    },
    methods: {
        signIn: async function({email, password}){
            Auth.configure({
                authenticationFlowType: 'USER_SRP_AUTH'
            })

            try {
                const user = await Auth.signIn(email, password);
                console.log('signIn',{user})
                this.$store.commit('user',user)
                return this.hubEvent
            } catch (err) {
                console.log(err)
                return err
            }
        },
        requestOTP: async function(username){
            let self = this
            Auth.configure({
                authenticationFlowType: 'CUSTOM_AUTH'
            })

            try {
                let response = await Auth.signIn(username).then(user=>{
                    self.temp.user = user
                    return true
                }).catch(function(err){
                    console.log(err)
                    return false
                });
                console.log('authorizer > requestOTP', response)
            } catch (err) {
                console.log('error signing in', err);
                return false
            }
            return true
        },
        signInOTP: async function(challengeResponse){
            let self = this
            Auth.configure({
                authenticationFlowType: 'CUSTOM_AUTH'
            })
            try {
                Auth.sendCustomChallengeAnswer(self.temp.user, challengeResponse)
                .then((user) => {
                    return user
                })
                .catch((err) => {
                    return err
                })
            } catch (err) {
                return err
            }
        },
        async requestEmailChange(email){
            let user = await Auth.currentAuthenticatedUser();
            this.temp.user = user
            try{
                let result = await Auth.updateUserAttributes(user, {
                    email
                });
                console.log('requestEmailChange',result)
                return true
            }catch(err){
                console.error('Error requestEmailChange',err)
                return false
            }
        },
        async verifyEmailChange(pin){
            try{
                let result = await Auth.verifyCurrentUserAttributeSubmit('email', pin)
                console.log('verifyEmailChange',result)
                return true
            }catch(err){
                console.error('Error verifyEmailChange',err)
                return false
            }
        },
        async signUp(data){
            let user
            try{
                user = await Auth.signUp(data)
                // this.$store.commit('user',user)
                return this.hubEvent
            }catch(err){
                console.log(err)
                return this.hubEvent
            }
        },
        async signOut() {
            try {
                let result = await Auth.signOut();
                this.$router.push({path:'/'})
                return this.hubEvent
            } catch (err) {
                console.log(err)
                return this.hubEvent
            }
        },
        async resendConfirmationCode(username) {
            try {
                let response = await Auth.resendSignUp(username);
                console.log('code resent successfully');
                return response
            } catch (err) {
                console.log('error resending code: ', err);
                return err
            }
        },
        async forgotPassword(username){
            try {
                let response = await Auth.forgotPassword(username)
                console.log({response, hubEvent: this.hubEvent})
                return response
            } catch (err) {
                console.log({err, hubEvent: this.hubEvent})
                return err
            }
        },
        async resetPassword(username, code, password){
            try {
                let response = await Auth.forgotPasswordSubmit(username, code, password)
                console.log('resetPassword', response)
                return response
            } catch (err) {
                console.log({err, hubEvent: this.hubEvent})
                return err
            }
        },
        async confirmSignUp({username, verificationCode}){
            let user
            try {
                user = await Auth.confirmSignUp(username, verificationCode);
                return this.hubEvent
            } catch (err) {
                return err
            }
        },
        async findUser(){
            let self = this
            let user
            try{
                user = await Auth.currentAuthenticatedUser()
                this.$store.commit('user',user)
            }catch(err){
                this.$store.commit('user',undefined)
            }
        },
        async federation(idp){
            console.log('authorizer > federation',{
                idp
            })
            try{
                let result = Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider[idp]})
                return result
            }catch(err){
                return err
            }

        },
        async updateAttribute(data){
            if(data){
                console.log('updateAttribute',data)
                try{
                    let user = await Auth.currentAuthenticatedUser();

                    try{
                        let result = await Auth.updateUserAttributes(user, data);
                        await this.findUser()
                        return result
                    }catch(err){
                        return err
                    }
                }catch(err){
                    return err
                }
            }
        },
        async sessionHandler(){
            let self = this
            let user = self.$store.getters.user
            clearTimeout(self.session.timer)
            if(user){
                self.session.expires = new Date(user.signInUserSession.accessToken.payload.exp*1000)

                let t = new Date()
                let time_left = (self.session.expires.getTime() - t.getTime()) / 1000
                self.session.time_left = time_left

                if(time_left<0){
                    if(self.session.inactivity>90){
                        console.log('Signing out')
                        await self.signOut()
                    }else{
                        console.log('Restarting session')
                        await self.findUser()
                    }
                }


                self.session.timer = setTimeout(function(){
                    self.sessionHandler()
                },(1000))
            }else{
                self.session.expires = null
                self.session.time_left = null
            }
        },
        trackInactivity: function(){
            let self = this
            self.session.inactivity++
            clearTimeout(self.session.inactivity_timer)
            self.session.inactivity_timer = setTimeout(function(){
                if(self.$store.getters.user){
                    self.trackInactivity()
                }
            },1000)
        }

    },
    computed: {
        language: function(){
            return this.$store.getters.language
        },
        user: function(){
            return this.$store.getters.user
        },
        debug: function(){
            return this.$store.getters.debug
        }
    },
    watch: {
        user: function(){
            let user = this.user
            if(user){
                this.$emit('signedIn')
            }else{
                this.$emit('signedOut')
            }
        }
    }
}
</script>

<style lang='scss'>

#MoreInfo {
    width: calc(100% + 40px);
    position: fixed;
    top: 52px;
    left: -20px;
    background-color: rgba(0,0,0,0.2);
    z-index: 1;
}
</style>
