module.exports = function(component){
  let self = this
  this.getMeetings = async function(){
      let output = []
      let response = await component.sendRequest('get','/api/meetings')
      if(response.status==200){
        let meetings = response.data
        output = meetings
      }  

      await self.getPreMeetingAnswers()
      await self.getPostMeetingAnswers()
      return output    
  }

  this.getAllMeetings = async function(){
      let output = []
      let response = await component.sendRequest('get','/api/admin/meetings')
      if(response.status==200){
        let meetings = response.data
        output = meetings
      }  

      await self.getPreMeetingAnswers()
      return output    
  }

  this.getPreMeetingAnswers = async function(){
    let response = await component.sendRequest('get',`/api/premeeting/${component.$store.getters.user.username}`)
    if(response.status==200){
      component.$store.commit('preMeetingAnswers',response.data)
    }
  }

  this.getPostMeetingAnswers = async function(){
    let response = await component.sendRequest('get',`/api/postmeeting/${component.$store.getters.user.username}`)
    if(response.status==200){
      component.$store.commit('postMeetingAnswers',response.data)
    }
  }
}